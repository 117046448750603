import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import L, { DivIcon } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { hoverSiteIcon } from '@/lib/map'

type SiteMapProps = {
  target: L.LatLng
  site: Site
}

const SiteMap = ({ target, site }: SiteMapProps) => {
  const { t } = useTranslation()

  const country = site.properties?.country

  const countries = {
    FR: t(i18nKeys.beeoinitiative.sites.table.FR),
    BE: t(i18nKeys.beeoinitiative.sites.table.BE),
    IT: t(i18nKeys.beeoinitiative.sites.table.IT),
  }
  const countryMaps = {
    FR: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1702_14245" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <circle cx="8" cy="8" r="8" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_1702_14245)">
        <mask id="mask1_1702_14245" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-3" y="0" width="22" height="16">
          <path d="M-2.28516 0H18.7464V15.7737H-2.28516V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_1702_14245)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.668 0H19.0489V16H10.668V0Z" fill="#F50100"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M-2.28516 0H5.60167V15.7737H-2.28516V0Z" fill="#2E42A5"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57422 0H11.4314V16H4.57422V0Z" fill="#F7FCFF"/>
        </g>
      </g>
    </svg>
  `,
    BE: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_belgium" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <circle cx="8" cy="8" r="8" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_belgium)">
        <path fill="#ED2939" d="M10.85 0H16V16H10.85V0Z"/>
        <path fill="#FAE042" d="M5.7 0H10.85V16H5.7V0Z"/>
        <path fill="#000000" d="M0.55 0H5.7V16H0.55V0Z"/>
      </g>
    </svg>
  `,
    IT: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="16" height="16" viewBox="0 0 256 256" xml:space="preserve">

    <defs>
    </defs>
    <g style="stroke: none; strokeWidth: 0; stroke-dasharray: none; strokeLinecap: butt; strokeLinejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	    <path d="M 60 2.571 v 84.859 c 17.466 -6.175 29.985 -22.818 30 -42.396 v -0.068 C 89.985 25.389 77.465 8.745 60 2.571 z" style="stroke: none; strokeWidth: 1; stroke-dasharray: none; strokeLinecap: butt; strokeLinejoin: miter; stroke-miterlimit: 10; fill: rgb(206,43,55); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
	    <path d="M 30 87.429 V 2.571 C 12.524 8.75 0 25.408 0 45 S 12.524 81.25 30 87.429 z" style="stroke: none; strokeWidth: 1; stroke-dasharray: none; strokeLinecap: butt; strokeLinejoin: miter; stroke-miterlimit: 10; fill: rgb(0,146,70); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
	    <path d="M 30 87.429 C 34.693 89.088 39.739 90 45 90 c 5.261 0 10.307 -0.911 15 -2.571 V 2.571 C 55.307 0.911 50.261 0 45 0 c -5.261 0 -10.307 0.912 -15 2.571 V 87.429 z" style="stroke: none; strokeWidth: 1; stroke-dasharray: none; strokeLinecap: butt; strokeLinejoin: miter; stroke-miterlimit: 10; fill: rgb(243,244,245); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
    </g>
    </svg>`,
  }

  const badgeIcon = new DivIcon({
    html: `
    <div class="absolute left-24 bottom-10 
            md:-translate-x-[170%] 
            lg:-translate-x-[150%] 
            xl:-translate-x-[90%] 
            2xl:-translate-x-[40%] 
            bg-white text-black text-center px-2 py-1 rounded-xl shadow-md flex items-center space-x-2 text-xs">
      <span>${countryMaps[country ?? '']}</span>
      <span>${countries[country ?? '']}</span>
    </div>
    `,
    iconSize: [0, 0],
  })

  return (
    <MapContainer
      className="relative z-0 h-full rounded-lg"
      center={target}
      zoom={5}
      dragging={false}
      scrollWheelZoom={false}
      attributionControl={false}
      zoomControl={false}
    >
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <Marker position={target} icon={hoverSiteIcon('pin')}>
        <Marker position={target} icon={badgeIcon} />{' '}
      </Marker>
    </MapContainer>
  )
}

export default SiteMap
